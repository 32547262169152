
const integrationURL = process.env.VUE_APP_URL;
const headers = { 'Authorization': 'Bearer '+ JSON.parse(localStorage.getItem('access_token'))};

const integrationModule = {
    state: () => ({
        networkElements: [],
        networkElementsByType: [],
        pboAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'text', value: '', disabled: false },
            { name: 'System ID', description: 'System ID of the Network Element', visibleOnDispatchWeb: false, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: false, inputType: 'text', value: '', disabled: true},
            { name: 'Fat Maximum Capacity', description: 'Maximum Capacity of the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'number', value: 0, disabled: false },
            { name: 'Fat Current Capacity', description: 'Current Capacity of the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'number', value: 0, disabled: true },
            { name: 'Fat Available Capacity', description: 'Available Capacity of the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'number', value: 0, disabled: true },
            { name: 'Place of Installation', description: 'Select Place of Installation', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'text', value: '', disabled: true },
            { name: 'Address', description: 'Address of the FAT', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile:false, visibleOnWeb: false, inputType: 'text', value: '', disabled: false },
            { name: 'Coordinates', description: 'Coordinates of the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'text', value: '', disabled: false },
            { name: 'QR Code Link', description: 'Link to Associated QR Code', visibleOnDispatchWeb: false, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: false, inputType: 'text', value: '', disabled: true },
            { name: 'FAT Existing Users ID', description: 'Ticket ID associated with the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: false, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            // { name: 'Picture', description: 'Picture of the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'file', value: '', disabled: true },
            { name: 'Element Status', description: 'Status of the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'text', value: '', disabled: false },
            { name: 'Creation Date', description: 'Date of Creation of the FAT', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'date', value: '', disabled: true },
            { name: 'Last Modification Date', description: 'Date of Last Modification of the FAT', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: true, inputType: 'date', value: '', disabled: true },
            { name: 'Updated By', description: 'User who last updated the FAT', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'text', value: '', disabled: true },
            // { name: 'Splitter 1', description: 'Existence of Splitter 1', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: false, splitterExists: false },
            // { name: 'Splitter 2', description: 'Existence of Splitter 2', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: false, splitterExists: false },
            // { name: 'Input Cable', description: 'Existence of Input Cable', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: false, cableExists: false },
            // { name: 'Output Cable 1', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: false, cableExists: false },
            // { name: 'Output Cable 2', description: 'Existence of Output Cable 2', type: 'Output Cable Selector', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: false, cableExists: false },
            // { name: 'Output Cable 3', description: 'Existence of Output Cable 3', type: 'Output Cable Selector', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: false, cableExists: false },
            { name: 'Previous Equipment', description: 'Previous Element connected to the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: false, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            { name: 'Next Equipment', description: 'Next Element connected to the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: false, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            { name: 'Brand', description: 'Brand of the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'text', value: '', disabled: false },
            { name: 'Brand Description', description: 'Description of the FAT', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'text', value: '', disabled: false },
            { name: 'Subsidiary', description: 'Relation to Subsidiary', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'text', value: '', disabled: true },
            { name: 'Client', description: 'Relation to Client', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'text', value: '', disabled: true },
            { name: 'Main Project', description: 'Relation to Main Project', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'text', value: '', disabled: true },
            { name: 'Project', description: 'Relation to Project', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'text', value: '', disabled: true },
            { name: 'Central Office', description: 'Relation to Central Office', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'text', value: '', disabled: true },
            { name: 'Cluster', description: 'Relation to Cluster', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'text', value: '', disabled: true },
            { name: 'Project Phase', description: 'Relation to Project Phase', visibleOnDispatchWeb: false, visibleOnDispatchMobile: false, visibleOnMobile: false, visibleOnWeb: false, inputType: 'text', value: '', disabled: true },
        ],
        cableAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'System ID', description: 'System ID of the Network Element', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Enclosure Origin', description: 'Origin of the Cable Enclosure', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Enclosure Destination', description: 'Destination of the Cable Enclosure', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Type', description: 'Type of Cable (Aerial/Underground)', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Capacity', description: 'Capacity of the Cable', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Fibers Active', description: 'Active Fibers of the Cable', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Fibers Reserved', description: 'Reserved Fibers of the Cable', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Fibers Dark', description: 'Dark Fibers of the Cable', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Fibers Dedicated', description: 'Dedicated Fibers of the Cable', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Length', description: 'Length of the Cable', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Cable Continuity', description: 'Identification of the cable continuity and hierarchy', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Brand', description: 'Brand of the Cable', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Brand Description', description: 'Brand Description of the Cable', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Subsidiary', description: 'Relation to Subsidiary', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Client', description: 'Relation to Client', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Main Project', description: 'Relation to Main Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project', description: 'Relation to Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Central Office', description: 'Relation to Central Office', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Cluster', description: 'Relation to Cluster', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project Phase', description: 'Relation to Project Phase', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
        ],
        spliceClosureAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'System ID', description: 'System ID of the Network Element', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Max Capacity', description: 'Maximum Capacity of the Splice Closure', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'number' },
            { name: 'Place of Installation', description: 'Select Place of Installation', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Address', description: 'Address of the Splice Closure', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disbaled: false },
            { name: 'Coordinates', description: 'Coordinates of the Splice Closure', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'QR Code Link', description: 'Link to Associated QR Code', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            // { name: 'Picture', description: 'Picture of the Splice Closure', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'file', value: '', disabled: true },
            { name: 'Element Status', description: 'Status of the Splice Closure', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Creation Date', description: 'Date of Creation of the Splice Closure', visibleOnMobile: false, value: '', inputType: 'date', disabled: true },
            { name: 'Last Modification Date', description: 'Date of Last Modification of the Splice Closure', visibleOnMobile: false, value: '', inputType: 'date', disabled: true },
            { name: 'Updated By', description: 'User who last updated the Splice Closure', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Previous Equipment', description: 'Previous Element', visibleOnDispatchWeb: true, visibleOnDispatchMobile: false, visibleOnMobile: true, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            { name: 'Next Equipment', description: 'Next Element connected', visibleOnDispatchWeb: true, visibleOnDispatchMobile: false, visibleOnMobile: true, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            // { name: 'Input Cable', description: 'Existence of Input Cable', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 1', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: true},
            // { name: 'Output Cable 2', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 3', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 4', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 5', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 6', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 7', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 8', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { id: 'Photo', description: 'Photo of the Splice Closure', visibleOnMobile: true },
            { name: 'Brand', description: 'Brand of the Splice Closure', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Brand Description', description: 'Brand Description of the Splice Closure', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Subsidiary', description: 'Relation to Subsidiary', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Client', description: 'Relation to Client', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Main Project', description: 'Relation to Main Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project', description: 'Relation to Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Central Office', description: 'Relation to Central Office', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Cluster', description: 'Relation to Cluster', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project Phase', description: 'Relation to Project Phase', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
        ],
        zoneAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'System ID', description: 'System ID of the Network Element', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'HP`s', description: 'Number of homes connected', visibleOnMobile: false, value: '', inputType: 'number', disabled: false },
            { name: 'Last Modification Date', description: 'Date of Last Modification of the Zone', visibleOnMobile: false, value: '', inputType: 'date', disabled: true },
            { name: 'Updated By', description: 'User who last updated the Zone', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Subsidiary', description: 'Relation to Subsidiary', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Client', description: 'Relation to Client', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Main Project', description: 'Relation to Main Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project', description: 'Relation to Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Central Office', description: 'Relation to Central Office', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Cluster', description: 'Relation to Cluster', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project Phase', description: 'Relation to Project Phase', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
        ],
        poleAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'System ID', description: 'System ID of the Network Element', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Address', description: 'Address of the Pole', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Coordinates', description: 'Coordinates of the pole', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'QR Code Link', description: 'Link to Associated QR Code', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            // { name: 'Picture', description: 'Picture of the Pole', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'file', value: '', disabled: true },
            { name: 'Element Status', description: 'Status of the Pole', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Creation Date', description: 'Date of Creation of the Pole', visibleOnMobile: false, value: '', inputType: 'date', disabled: true },
            { name: 'Last Modification Date', description: 'Date of Last Modification of the Pole', visibleOnMobile: false, value: '', inputType: 'date', disabled: true },
            { name: 'Updated By', description: 'User who last updated the Pole', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Pole Material', description: 'Material of the pole', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Pole height', description: 'Height of the pole', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Top Diameter', description: 'Top Diameter of the Pole', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Bottom Diameter', description: 'Bottom Diameter of the pole', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Down Guy', description: 'Presence of a Down Guy', visibleOnMobile: false, value: '', inputType: 'text', dropGuyExists: false, disabled: false },
            { name: 'Previous Equipment', description: 'Previous Element', visibleOnDispatchWeb: true, visibleOnDispatchMobile: false, visibleOnMobile: true, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            { name: 'Next Equipment', description: 'Next Element connected', visibleOnDispatchWeb: true, visibleOnDispatchMobile: false, visibleOnMobile: true, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            { name: 'Brand', description: 'Brand of the Material', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Description', description: 'Description of the Material', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Subsidary', description: 'Relation to Subsidary', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Client', description: 'Relation to Client', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Main Project', description: 'Relation to Main Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project', description: 'Relation to Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Central Office', description: 'Relation to Central Office', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Cluster', description: 'Relation to Cluster', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project Phase', description: 'Relation to Project Phase', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
        ],
        manholeAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'System ID', description: 'System ID of the Network Element', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Address', description: 'Address of the Manhole', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Coordinates', description: 'Coordinates of the Manhole', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'QR Code Link', description: 'Link to Associated QR Code', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            // { name: 'Picture', description: 'Picture of the Manhole', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'file', value: '', disabled: true },
            { name: 'Element Status', description: 'Status of the Manhole', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Creation Date', description: 'Date of Creation of the Manhole', visibleOnMobile: false, value: '', inputType: 'date', disabled: true },
            { name: 'Last Modification Date', description: 'Date of Last Modification of the Manhole', visibleOnMobile: false, value: '', inputType: 'date', disabled: true },
            { name: 'Previous Equipment', description: 'Previous Element', visibleOnDispatchWeb: true, visibleOnDispatchMobile: false, visibleOnMobile: true, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            { name: 'Next Equipment', description: 'Next Element connected', visibleOnDispatchWeb: true, visibleOnDispatchMobile: false, visibleOnMobile: true, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            { name: 'Updated By', description: 'User who last updated the Manhole', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Manhole Material', description: 'Material of the Manhole', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Manhole Dimensions', description: 'Dimensions of the Manhole', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Manhole Cover', description: 'Material of the Manhole Cover', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Brand', description: 'Brand of the Material', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Brand Description', description: 'Brand Description of the Material', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Subsidiary', description: 'Relation to Subsidiary', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Client', description: 'Relation to Client', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Main Project', description: 'Relation to Main Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project', description: 'Relation to Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Central Office', description: 'Relation to Central Office', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Cluster', description: 'Relation to Cluster', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project Phase', description: 'Relation to Project Phase', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
        ],
        loopAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'System ID', description: 'System ID of the Network Element', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Last Modification Date', description: 'Date of Last Modification of the Loop', visibleOnMobile: false, value: '', inputType: 'date', disabled: true },
            { name: 'Updated By', description: 'User who last updated the Loop', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Loop Length', description: 'Length of the loop', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            // { name: 'Picture', description: 'Picture of the Loop', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'file', value: '', disabled: true },
            { name: 'QR Code Link', description: 'Link to Associated QR Code', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Brand', description: 'Brand of the Material', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Brand Description', description: 'Brand Description of the Material', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Subsidiary', description: 'Relation to Subsidiary', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Client', description: 'Relation to Client', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Main Project', description: 'Relation to Main Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project', description: 'Relation to Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Central Office', description: 'Relation to Central Office', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Cluster', description: 'Relation to Cluster', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project Phase', description: 'Relation to Project Phase', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
        ],
        fdtSroAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'System ID', description: 'System ID of the Network Element', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Type of Enclosure', description: 'Type of Enclosure', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Place of Installation', description: 'Select Place of Installation', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Address', description: 'Address of the FDT-SRO', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'Coordinates', description: 'Coordinates of the FDT-SRO', visibleOnMobile: false, value: '', inputType: 'text'},
            { name: 'QR Code Link', description: 'Link to Associated QR Code', visibleOnMobile: false, value: '', inputType: 'text'},
            // { name: 'Picture', description: 'Picture of the FDT-SRO', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'file', value: '', disabled: true },
            { name: 'Element Status', description: 'Status of the FDT-SRO', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'Creation Date', description: 'Date of Creation of the FDT-SRO', visibleOnMobile: false, value: '', inputType: 'date', disabled: true },
            { name: 'Last Modification Date', description: 'Date of Last Modification of the FDT-SRO', visibleOnMobile: false, value: '', inputType: 'date', disabled: true },
            { name: 'Previous Equipment', description: 'Previous Element', visibleOnDispatchWeb: true, visibleOnDispatchMobile: false, visibleOnMobile: true, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            { name: 'Next Equipment', description: 'Next Element connected', visibleOnDispatchWeb: true, visibleOnDispatchMobile: false, visibleOnMobile: true, visibleOnWeb: false, inputType: 'dropdown', value: '', disabled: true },
            { name: 'Updated By', description: 'User who last updated the FDT-SRO', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Maximum Capacity', description: 'Maximum Capacity of the FDT-SRO', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'number' },
            { name: 'Splitter', description: 'Existence of Splitter', visibleOnMobile: false, inputType: 'text', value: '', disabled: false, splitterExists: false },
            // { name: 'Input Cable', description: 'Existence of Input Cable', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 1', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: true},
            // { name: 'Output Cable 2', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 3', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 4', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 5', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 6', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 7', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            // { name: 'Output Cable 8', description: 'Existence of Output Cable 1', type: 'Output Cable Selector', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, inputType: 'dropdown', value: '', disabled: false, cableExists: false},
            { name: 'Brand', description: 'Brand of the Material', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Brand Description', description: 'Brand Description of the Material', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Subsidiary', description: 'Relation to Subsidiary', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Client', description: 'Relation to Client', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Main Project', description: 'Relation to Main Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project', description: 'Relation to Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Central Office', description: 'Relation to Central Office', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Cluster', description: 'Relation to Cluster', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project Phase', description: 'Relation to Project Phase', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
        ],
        sfuAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'System ID', description: 'System ID of the Network Element', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Address', description: 'Address of the SFU', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Coordinates', description: 'Coordinates of the SFU', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Link to Dispatch', description: 'Link to Dispatch', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'FAT ID', description: 'FAT connected ', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'MAC Address', description: 'Mac Address of the Router', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'GPON Address', description: 'GPON Address of the Router', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Subsidiary', description: 'Relation to Subsidiary', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Client', description: 'Relation to Client', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Main Project', description: 'Relation to Main Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project', description: 'Relation to Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Central Office', description: 'Relation to Central Office', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Cluster', description: 'Relation to Cluster', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project Phase', description: 'Relation to Project Phase', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
        ],
        buildingAttributes: [
            { name: 'Name', description: 'Element Name', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: false },
            { name: 'System ID', description: 'System ID of the Network Element', visibleOnMobile: false, value: '', inputType: 'text', disabled: true },
            { name: 'Address', description: 'Address of the Building', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Coordinates', description: 'Coordinates of the Building', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Building Name', description: 'Name of the Building', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '',  inputType: 'text', disabled: false },
            { name: 'Total Flats', description: 'Total Number of apartments/ HP`S', visibleOnMobile: false, value: '', inputType: 'number', disabled: false },
            { name: 'Floors', description: 'Number of floors', visibleOnMobile: false, value: '', inputType: 'number', disabled: false },
            { name: 'Flats per floor', description: 'Number of apartments per Floor', visibleOnMobile: false, value: '', inputType: 'number', disabled: false },
            { name: 'SFU IDS', description: 'SFU IDS', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'FAT ID', description: 'FAT Connected', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Manager Name', description: 'Manager Name', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Manager Contact', description: 'Manager Contact', visibleOnMobile: false, value: '', inputType: 'text', disabled: false },
            { name: 'Subsidiary', description: 'Relation to Subsidiary', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Client', description: 'Relation to Client', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Main Project', description: 'Relation to Main Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project', description: 'Relation to Project', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Central Office', description: 'Relation to Central Office', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Cluster', description: 'Relation to Cluster', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },
            { name: 'Project Phase', description: 'Relation to Project Phase', visibleOnDispatchWeb: true, visibleOnDispatchMobile: true, visibleOnMobile: true, visibleOnWeb: true, value: '', inputType: 'text', disabled: true },       
        ],      
        networkElementFeatures: [
            { feature: 'Attributes', description: 'Add Attributes', isConfigurable: true },
            { feature: 'Terminal Access', description: 'Connection to Clients', isConfigurable: false },
            { feature: 'Image', description: 'Capture/Upload Image', isConfigurable: true },
            { feature: 'Zone', description: 'Add Zone', isConfigurable: true },
            { feature: 'Geometry', description: 'Define Type of Geometry', isConfigurable: true },
        ],
        splitterTypes: [
            { label: "1:4", type: "1:4"},
            { label: "1:8", type: "1:8"},
            { label: "1:16", type: "1:16"},
            { label: "1:32", type: "1:32"},
            { label: "1:64", type: "1:64"},
        ],
        cableCapacities: [
            { label: "6FO Cable", type: "6FO"},
            { label: "12FO Cable", type: "12FO"},
            { label: "24FO Cable", type: "24FO"},
            { label: "48FO Cable", type: "48FO"},
        ],
        cableTypes: [
            { label: "Aerial", type: "Aerial"},
            { label: "Underground", type: "Underground"},
        ],
        lineTypes: [
            { label: "Solid", type: "solid", raw: "1px solid #000000"},
            { label: "Dashed", type: "dashed", raw: "1px dashed #000000"}
        ],
        deleteEndpointMap : {
            'PBO-FAT': '/networkelements/pbofat/template',
            'Cable': '/networkelements/cable/template',
            'Splice Closure': '/networkelements/spliceclosure/template',
            'Zone NRO': '/networkelements/zone/template',
            'Pole': '/networkelements/pole/template',
            'Manhole': '/networkelements/manhole/template',
            'Loop': '/networkelements/loop/template',
            'FDT-SRO': '/networkelements/fdt/template',
            'SFU': '/networkelements/sfu/template',
            'Building': '/networkelements/building/template'
            // Add more types as needed
        },
        icons: [
            'tabler:circle',
            'tabler:circle-filled',
            'tabler:circle-half',
            'tabler:triangle',
            'tabler:triangle-filled',
            'tabler:rectangle',
            'tabler:rectangle-filled',
            'tabler:square',
            'tabler:square-filled',
            'tabler:hexagon',
            'tabler:hexagon-filled',
            'tabler:octagon',
            'tabler:octagon-filled',
            'tabler:star',
            'tabler:star-filled',
            'tabler:star-half',
            'tabler:flag-2',
            'tabler:flag-2-filled',
            'tabler:home',
            'tabler:home-filled',
            'geo:turf-center',
            'fa-regular:building',
            'fa-solid:map',
            'carbon:building',
            'carbon:location',
            'carbon:location-filled',
            'carbon:location-star',
            'carbon:location-star-filled',
            'carbon:pin',
            'carbon:pin-filled',
            'carbon:plug',
            'carbon:radar',
            'carbon:radar-enhanced',
            'carbon:router-wifi',
            'carbon:tcp-ip-service',
            'carbon:undefined',
            'carbon:unknown',
            'carbon:unknown-filled',
            'carbon:undefined-filled',
            'carbon:wifi',
            'carbon:3d-cursor',
            'carbon:4k',
            'carbon:area',
            'carbon:asset'
        ],

    }),
    mutations: {
        setNetworkElements(state, networkElements) {
            state.networkElements = networkElements;
        },
        setPboAttributes(state, pboAttributes) {
            state.pboAttributes = pboAttributes;
        },
        setCableAttributes(state, cableAttributes) {
            state.cableAttributes = cableAttributes;
        },
        setSpliceClosureAttributes(state, spliceClosureAttributes) {
            state.spliceClosureAttributes = spliceClosureAttributes;
        },
        setNetworkElementFeatures(state, networkElementFeatures) {
            state.networkElementFeatures = networkElementFeatures;
        },
        setNetworkElementsByType(state, networkElementsByType) {
            state.networkElementsByType = networkElementsByType;
        },
    },
    actions: {
        async fetchNetworkElements(state) {
            const requestOptions = {
                method: 'GET',
                headers
            };
            const res = await fetch(
                integrationURL + '/networkelements/types',
                requestOptions
            );
            const data = await res.json();
            if (data.status == true) {
                state.commit('setNetworkElements', data.data);
            }
        },
        async submitPboFatTemplate(payload) {
            console.log('Payload:', payload);
            const requestOptions = {
                method: 'POST',
                headers,
                body: JSON.stringify(payload),
            };
            const res = await fetch(
                integrationURL + '/pbofat/template',
                requestOptions
            );
            const data = await res.json();
            if (data.status == true) {
                console.log('PBO/FAT added successfully');
            }
        },
        async fetchNetworkElementsByType(state, type) {
            console.log('Type:', type);

            const endpointMap = {
              'PBO-FAT': '/networkelements/pbofat/templates',
              'Cable': '/networkelements/cable/templates',
              'Splice Closure': '/networkelements/spliceclosure/templates',
              'Zone NRO': '/networkelements/zone/templates',
              'Pole': '/networkelements/pole/templates',
              'Manhole': '/networkelements/manhole/templates',
              'Loop': '/networkelements/loop/templates',
              'FDT-SRO': '/networkelements/fdt/templates',
              'SFU': '/networkelements/sfu/templates',
              'Building': '/networkelements/building/templates'
              // Add more types as needed
            };
          
            const endpoint = endpointMap[type];
            
            if (endpoint) {
              const requestOptions = {
                method: 'GET',
                headers,
              };
          
              try {
                const response = await fetch(`${integrationURL}${endpoint}`, requestOptions);
                const data = await response.json();
                state.commit('setNetworkElementsByType', data.data);
              } catch (error) {
                console.error(`Failed to fetch data for type ${type}:`, error);
              }
            } else {
              console.warn('Unknown network element type:', type);
            }
        },
        async deleteNetworkElementsByType(state, payload) {
            console.log('Payload:', payload);
            console.log('State:', state);
            const endpoint = state.state.deleteEndpointMap[payload.type];
            const requestOptions = {
                method: 'DELETE',
                headers,
            };
            const res = await fetch(
                integrationURL + endpoint + '/' + payload.id,
                requestOptions
            );
            const data = await res.json();
            console.log('Data:', data);
            if (data.status === true) {
                console.log('Network Element deleted successfully');
                // this.fetchNetworkElementsByType(payload.type);
                return { status: true, message: 'Network Element deleted successfully' };
            } else {
                console.log('Failed to delete Network Element');
                return { status: false, message: `Failed to delete Network Element: ${data.message}` };
            }
        }
    },
    getters: {
        getNetworkElements: state => state.networkElements,
        getPboAttributes: state => state.pboAttributes,
        getCableAttributes: state => state.cableAttributes,
        getSpliceClosureAttributes: state => state.spliceClosureAttributes,
        getZoneAttributes: state => state.zoneAttributes,
        getPoleAttributes: state => state.poleAttributes,
        getManholeAttributes: state => state.manholeAttributes,
        getLoopAttributes: state => state.loopAttributes,
        getFDTAttributes: state => state.fdtSroAttributes,
        getSFUAttributes: state => state.sfuAttributes,
        getBuildingAttributes: state => state.buildingAttributes,
        getNetworkElementFeatures: state => state.networkElementFeatures,
        getCableCapacities: state => state.cableCapacities,
        getCableTypes: state => state.cableTypes,
        getLineTypes: state => state.lineTypes,
        getNetworkElementsByType: state => state.networkElementsByType,
        getIcons: state => state.icons,
        getSplitterTypes: state => state.splitterTypes,
    }
}

export default integrationModule;